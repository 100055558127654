import React, { Component, } from 'react'
import { Field, reduxForm } from 'redux-form'
import { TextInput, } from 'components/MaterialInputs'
import Selector from 'components/Selector'
import FormField from 'components/FormField'
import Loader from 'components/Loader'
import Button from 'components/Button'
import _ from 'lodash'
import moment from 'moment'
import { Tooltip } from '@mui/material'
import AdditionalDocumentUploader from 'components/AdditionalDocumentUploader'

import {
  COUNTRIES,
  USA_STATES,
  CANADA_PROVINCES,
} from '../../../../constants/geography'
import CTAValidators from '../Validators/CTAValidations'
import './MerchantInfoCTA.scss'
import validator from '../../../SignIn/components/ForgotPasswordForm/validators';

class MerchantInfoCTA extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fieldChanges: false,
      errors: [],
      loading: true,
      verified: false,
      verifiedDocs: false,
      isSubmitting: false,
      loadingText: 'Gathering account information',
      ctaDocumentsRequested: [],
      ctaDocumentsRequestedIds: {},
      stackPayTOS: false,
      stackSportsTOS: false,
    }
  }

  componentDidMount() {
    this.resetState()
    Window.loadingText1 = setTimeout(() => this.setState({
      loadingText: 'Verifying processor account data' }), 3000)
    Window.loadingText2 = setTimeout(() => this.setState({
      loadingText: 'Processing account verification status' }), 6500)
    Window.loadingText3 = setTimeout(() => this.setState({
      loadingText: 'Our apologies, this is taking a while' }), 24000)
  }

  componentDidUpdate(prevProps) {
    if (this.props.ctaInfo && (this.props.ctaInfo !== prevProps.ctaInfo)) {
      const ctaDocumentsRequested = []
      const ctaDocumentsRequestedIds = {}
      if (this.props.ctaInfo && this.props.ctaInfo.cta_documents_requested.length > 0) {
        // loop through the cta_documents_requested object and extract the keys and values
        this.props.ctaInfo.cta_documents_requested.forEach((doc) => {
          Object.keys(doc).forEach((key) => {
            ctaDocumentsRequested.push(key)
            ctaDocumentsRequestedIds[key] = doc[key]
          })
        })
        this.setState({
          ctaDocumentsRequested, ctaDocumentsRequestedIds, hasAllRequestedDocuments: false, verifiedDocs: 'false'
        })
      } else {
        this.setState({
          ctaDocumentsRequested,
          ctaDocumentsRequestedIds,
          hasAllRequestedDocuments: true,
          verifiedDocs: 'true'
        })
      }
      const ctaCategories = this.props.ctaInfo && this.props.ctaInfo.cta_cta_categories
      if (ctaCategories.some((c) => c.category_name !== 'business')
        && ctaCategories.some((c) => c.category_name !== 'identity')) {
        this.setState({ verified: 'true' })
      } else {
        this.setState({ verified: 'false' })
      }
    }

    if (this.props.formInfo && (this.props.formInfo !== prevProps.formInfo)) {
      const { formInfo } = this.props
      clearTimeout(Window.loadingText1)
      clearTimeout(Window.loadingText2)
      clearTimeout(Window.loadingText3)

      this.setState({
        loading: false,
        loadingText: 'Gathering account information',
        merchantId: formInfo.id,
        businessEin: formInfo.businessEin.replace('-', ''),
        businessLegalName: formInfo.businessLegalName,
        businessAddress1: formInfo.businessAddress1,
        businessAddress2: formInfo.businessAddress2,
        businessCity: formInfo.businessCity,
        businessState: formInfo.businessState,
        businessZip: formInfo.businessZip,
        businessCountry: formInfo.businessCountry,
        firstName: formInfo.contacts.owners[0].first_name,
        lastName: formInfo.contacts.owners[0].last_name,
        contactAddress1: formInfo.contacts.owners[0].address_1,
        contactAddress2: formInfo.contacts.owners[0].address_2,
        contactCity: formInfo.contacts.owners[0].city,
        contactZip: formInfo.contacts.owners[0].postal_code,
        contactState: formInfo.contacts.owners[0].state,
        contactCountry: formInfo.contacts.owners[0].country_id,
        contactDOB: moment.utc(formInfo.contacts.owners[0].date_of_birth).format('MM/DD/YYYY').toString(),
        contactSsn: formInfo.contacts.owners[0].ssn_last_4,
        contactSsnLast4: formInfo.contacts.owners[0].ssn_last_4,
      })
    }

    // check when this.props.uploadedFilesStore is updated to see if all requested documents have been submitted
    if (!this.props.removingFile && this.props.uploadedFilesStore !== prevProps.uploadedFilesStore) {
      const { ctaDocumentsRequested } = this.state
      const missingDocuments = new Set()

      // loop through all requested documents and check if they have been submitted
      ctaDocumentsRequested.forEach((fileType) => {
        const file = this.props.uploadedFilesStore[fileType]
        if (!file || !file.uuid || file.error || file.deleted) {
          missingDocuments.add(fileType)
        }
      })

      this.setState({ hasAllRequestedDocuments: missingDocuments.size === 0 })
    } else if (this.props.removingFile) {
      this.setState({ hasAllRequestedDocuments: false })
    }

    if (!this.props.isUpdating && prevProps.isUpdating) {
      this.setState({ isSubmitting: false })
    }
  }

  handleChange = (value, property) => {
    let formattedValue = value
    if (property === 'contactSsn') {
      formattedValue = this.formatSSN(value)
    }
    this.setState({ [property]: formattedValue, fieldChanges: true, })
  }

  resetState = () => {
    const { formInfo = '' } = this.props

    if (formInfo) {
      this.setState(
        {
          merchantId: formInfo.id,
          businessEin: formInfo.businessEin.replace('-', ''),
          businessLegalName: formInfo.businessLegalName,
          businessAddress1: formInfo.businessAddress1,
          businessAddress2: formInfo.businessAddress2,
          businessCity: formInfo.businessCity,
          businessState: formInfo.businessState,
          businessZip: formInfo.businessZip,
          businessCountry: formInfo.businessCountry,

          firstName: formInfo.contacts.owners[0].first_name,
          lastName: formInfo.contacts.owners[0].last_name,
          contactAddress1: formInfo.contacts.owners[0].address_1,
          contactAddress2: formInfo.contacts.owners[0].address_2,
          contactCity: formInfo.contacts.owners[0].city,
          contactZip: formInfo.contacts.owners[0].postal_code,
          contactState: formInfo.contacts.owners[0].state,
          contactCountry: formInfo.contacts.owners[0].country_id,
          contactDOB: moment.utc(formInfo.contacts.owners[0].date_of_birth).format('MM/DD/YYYY').toString(),
          contactSsn: formInfo.contacts.owners[0].ssn_last_4,
          contactSsnLast4: formInfo.contacts.owners[0].ssn_last_4,

          fieldChanges: false,
          stateOptions: [],
          errors: [],
          isSubmitting: false,
          loadingText: 'Gathering account information'
        }
      )
    }
  }

  _getStateOptions = (country) => (country === 'USA' ? USA_STATES : CANADA_PROVINCES)

  _onChangeBusiness = (data) => {
    this.validate('businessState', data.value)
    this.setState({ businessState: data.value, fieldChanges: true, })
  }
  _onChangecontact = (data) => {
    this.validate('contactState', data.value)
    this.setState({ contactState: data.value, fieldChanges: true })
  }

  validate = (property, value) => {
    this.deleteErrorByProperty({
      property,
      callback: () => {
        const result = CTAValidators[property].validate(value, this.props.formInfo)
        if (result) this.setState({ errors: [...this.state.errors, result,], })
      },
    })
  }

  deleteErrorByProperty = ({ property, callback = null, }) => {
    const errorsWithoutProperty = this.state.errors.filter((err) => err.name !== property)
    this.setState({ errors: errorsWithoutProperty, }, callback)
  }

  formatSSN = (ssn) => {
    const onlyNums = ssn.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
      return onlyNums
    }
    if (onlyNums.length <= 5) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(5, 9)}`
  }

  submit = () => {
    // prevent multiple submissions
    if (this.state.isSubmitting === true) return
    this.setState({ isSubmitting: true, })

    const { businessEin, businessLegalName, businessAddress1, businessAddress2, businessCity, businessState,
      businessCountry, businessZip, firstName, lastName, contactAddress1, contactAddress2, contactCity, contactState,
      contactZip, contactCountry, contactDOB, contactSsn, merchantId, ctaDocumentsRequested } = this.state

    const fields = {}
    const ctaCategories = this.props.ctaInfo && this.props.ctaInfo.cta_cta_categories

    if (ctaCategories.some((c) => c.category_name === 'business')) {
      fields.businessEin = businessEin
      fields.businessLegalName = businessLegalName
      fields.businessAddress1 = businessAddress1
      fields.businessAddress2 = businessAddress2
      fields.businessCity = businessCity
      fields.businessZip = businessZip
      if (businessCountry !== 'GBR') {
        fields.businessState = businessState
      }
    }

    if (ctaCategories.some((c) => c.category_name === 'identity')) {
      fields.firstName = firstName
      fields.lastName = lastName
      fields.contactAddress1 = contactAddress1
      fields.contactAddress2 = contactAddress2
      fields.contactCity = contactCity
      fields.contactZip = contactZip
      fields.contactDOB = contactDOB
      fields.contactSsn = contactSsn.replace(/-/g, '')
      const cleanSsn = fields.contactSsn
      fields.contactSsnLast4 = cleanSsn.length === 9 ? cleanSsn.slice(-4) : ''

      if (contactCountry !== 'GBR') {
        fields.contactState = contactState
      }
    }

    const errors = []
    Object.keys(fields).forEach((key) => {
      const result = CTAValidators[key].validate(fields[key], this.props.formInfo)
      if (result) errors.push(result)
    })

    if (errors.length) {
      this.setState({ errors, })
      this.setState({ isSubmitting: false, })
      // scroll to the top of the form to show the errors
      const element = document.getElementById('cta-form-wraper')
      if (element) element.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      const payload = {
        merchantId,
      }
      if (ctaDocumentsRequested.length > 0) {
        // map over the onjects of the uploadedFilesStore an array of objects with fileId and fileType documentTypeId
        payload.ctaDocumentsRequested = Object.entries(this.props.uploadedFilesStore).map(([fileType, file]) => {
          return {
            documentTypeId: this.state.ctaDocumentsRequestedIds[fileType],
            fileId: file.id,
          }
        })
      }
      if (ctaCategories.some((c) => c.category_name === 'business')) {
        payload.business = {
          businessEin,
          businessLegalName,
          businessAddress1,
          businessAddress2,
          businessCity,
          businessState,
          businessCountry,
          businessZip
        }
      }
      if (ctaCategories.some((c) => c.category_name === 'identity')) {
        payload.identity = {
          firstName,
          lastName,
          contactAddress1,
          contactAddress2,
          contactCity,
          contactState,
          contactZip,
          contactCountry,
          contactDOB,
          contactSsn: contactSsn.replace(/-/g, ''),
          contactSsnLast4: contactSsn.replace(/-/g, '').slice(-4)
        }
      }
      this.props.onSubmit(payload)
    }
  }

  submitMessage = () => {
    this.setState({ isSubmitting: true, })
    const { merchantId } = this.state
    const payload = { merchantId }
    this.props.onSubmit(payload)
  }

  additionalDocumentsObj = {
    contactId: {
      html: <div>
        <h3>Drivers License or State ID or Passport</h3>
        <ul>
          <li>Make sure the document provided is for the correct person attempted to be verified</li>
          <li>Drivers License or State ID or Passport cannot be expired</li>
          <li>Your document image must be clear, and easily readable</li>
          <li>Your document cannot be scan and can only be in PNG or JPG format and cannot exceed 10MB</li>
        </ul>
      </div>,
      acceptedFiles: '.png, .jpg, .jpeg'
    },
    contactSsn: {
      html: <div>
        <h3>Social Security Card</h3>
        <ul>
          <li>Make sure the document provided is for the correct person attempted to be verified</li>
          <li>Your document image must be clear, and easily readable</li>
          <li>Your document cannot be scan and can only be in PNG or JPG format and cannot exceed 10MB</li>
        </ul>
      </div>,
      acceptedFiles: '.png, .jpg, .jpeg'
    },
    beneficialOwnerDocument: {
      html: <div>
        <h3>Beneficial Owner Document</h3>
        <ul>
          <li>Ensure your document is completely filled out including your signature</li>
          <li>Your document image must be clear, and easily readable</li>
          <li>Your document must be in PDF format and cannot exceed 10MB</li>
        </ul>
      </div>,
      acceptedFiles: '.pdf'
    },
    _501c3IrsForm: {
      html: <div>
        <h3>501(c)(3) IRS Form</h3>
        <ul>
          <li>Your document image must be clear, and easily readable</li>
          <li>Your document can only be in PNG, JPG, or PDF format and cannot be more than 10MB</li>
        </ul>
      </div>,
      acceptedFiles: '.png, .jpg, .jpeg, .pdf'
    },
    w9IrsForm: {
      html: <div>
        <h3>W9 IRS Form</h3>
        <ul>
          <li>Your document image must be clear, and easily readable</li>
          <li>Your document can only be in PNG, JPG, or PDF format and cannot be more than 10MB</li>
        </ul>
      </div>,
      acceptedFiles: '.png, .jpg, .jpeg, .pdf'
    },
    i47cIrsLetter: {
      html: <div>
        <h3>I47(c) IRS Letter</h3>
        <ul>
          <li>Your document image must be clear, and easily readable</li>
          <li>Your document can only be in PNG, JPG, or PDF format and cannot be more than 10MB</li>
        </ul>
      </div>,
      acceptedFiles: '.png, .jpg, .jpeg, .pdf'
    },
    ss4ConfirmationLetter: {
      html: <div>
        <h3>SS-4 Confirmation Letter</h3>
        <ul>
          <li>Your document image must be clear, and easily readable</li>
          <li>Your document can only be in PNG, JPG, or PDF format and cannot be more than 10MB</li>
        </ul>
      </div>,
      acceptedFiles: '.png, .jpg, .jpeg, .pdf'
    },
    bodForm: {
      html: <div>
        <h3>BOD Form</h3>
        <ul>
          <li>Your document image must be clear, and easily readable</li>
          <li>Your document can only be in PNG, JPG, or PDF format and cannot be more than 10MB</li>
        </ul>
      </div>,
      acceptedFiles: '.png, .jpg, .jpeg, .pdf'
    },
    meetingMinutes: {
      html: <div>
        <h3>Meeting Minutes</h3>
        <ul>
          <li>Your document image must be clear, and easily readable</li>
          <li>Your document can only be in PNG, JPG, or PDF format and cannot be more than 10MB</li>
        </ul>
      </div>,
      acceptedFiles: '.png, .jpg, .jpeg, .pdf'
    },
    voidedCheck: {
      html: <div>
        <h3>Voided Check</h3>
        <ul>
          <li>Ensure your check displays the last four digits of the{' '}
            account and the DBA or Legal name of your business/organization.</li>
          <li>Ensure the last four digits of the account number as</li>
          <li>Photocopies are not accepted</li>
          <li>Documents must be uploaded in full color (i.e. no black-and-white scans)</li>
          <li>Your document image must be clear, and easily readable</li>
          <li>Your document can only be in PNG, JPG, or PDF format and cannot be more than 10MB</li>
        </ul>
      </div>,
      acceptedFiles: '.png, .jpg, .jpeg, .pdf'
    },
    bankStatements: {
      html: <div>
        <h3>Bank Statements</h3>
        <ul>
          <li>Ensure your check displays the last four digits of the{' '}
            account and the DBA or Legal name of your business/organization.</li>
          <li>Photocopies are not accepted</li>
          <li>Documents must be uploaded in full color (i.e. no black-and-white scans)</li>
          <li>Your document image must be clear, and easily readable</li>
          <li>Your document can only be in PNG, JPG, or PDF format and cannot be more than 10MB</li>
        </ul>
      </div>,
      acceptedFiles: '.png, .jpg, .jpeg, .pdf'
    },
  }

  _handleStackPayTOSChange = (event, value) => {
    this.setState({
      stackPayTOS: value,
    })
  }
  _handleStackTOSChange = (event, value) => {
    this.setState({
      stackSportsTOS: value,
    })
  }

  render() {
    const { open, handleClose, ctaInfo, isUpdating, submitError } = this.props
    const {
      businessEin,
      businessLegalName,
      businessAddress1,
      businessAddress2,
      businessCity,
      businessState,
      businessZip,
      firstName,
      lastName,
      contactAddress1,
      contactAddress2,
      contactCity,
      contactState,
      contactZip,
      contactDOB,
      contactSsn,
      errors,
      loading,
      verified,
      loadingText,
      hasAllRequestedDocuments,
      ctaDocumentsRequested,
      verifiedDocs,
      stackPayPricing,
      stackPayTOS,
      stackSportsTOS,
    } = this.state

    const contactCountry = this.state.contactCountry ? _.find(COUNTRIES, { value: this.state.contactCountry, }) : ''
    const businessCountry = this.state.businessCountry ? _.find(COUNTRIES, { value: this.state.businessCountry, }) : ''

    const businessEinError = errors.find((err) => err.name === 'businessEin')
    const businessLegalNameError = errors.find((err) => err.name === 'businessLegalName')
    const businessAddress1Error = errors.find((err) => err.name === 'businessAddress1')
    const businessAddress2Error = errors.find((err) => err.name === 'businessAddress2')
    const businessCityError = errors.find((err) => err.name === 'businessCity')
    const businessStateError = errors.find((err) => err.name === 'businessState')
    const businessZipError = errors.find((err) => err.name === 'businessZip')

    const firstNameError = errors.find((err) => err.name === 'firstName')
    const lastNameError = errors.find((err) => err.name === 'lastName')
    const contactAddress1Error = errors.find((err) => err.name === 'contactAddress1')
    const contactAddress2Error = errors.find((err) => err.name === 'contactAddress2')
    const contactCityError = errors.find((err) => err.name === 'contactCity')
    const contactStateError = errors.find((err) => err.name === 'contactState')
    const contactZipError = errors.find((err) => err.name === 'contactZip')
    const contactSsnError = errors.find((err) => err.name === 'contactSsn')
    const contactSsnLast4Error = errors.find((err) => err.name === 'contactSsnLast4')
    const contactDOBError = errors.find((err) => err.name === 'contactDOB')

    const tooltipMessage = `
    This information is required as part of StackPay’s mandatory identity verification requirement:
    StackPay must collect, verify, and maintain information on the individuals associated with every
    StackPay account, as required by our regulators and financial partners.
    This is intended to promote transparency and prevent individuals from using complex company structures
    to hide terrorist financing, money laundering, tax evasion, and other financial crimes.
    For more information visit FinCEN.gov.
    `

    const addressTooltipMessage = `
    A physical address must be provided as part of our due diligence and verification obligations
    regarding Anti-Money Laundering (AML) compliance.
    `

    const ctaCategories = ctaInfo && ctaInfo.cta_cta_categories
    const canBeDismissed = _.get(ctaInfo, 'can_be_dismissed', '').toString() === 'true'
    const termsOfService = _.get(ctaInfo, 'terms_of_service', '').toString() === 'true'

    return (
      <React.Fragment>
        {open && (
          <div className='cta-wrapper'>
            <div className='cta-container'>
              {loading ? (
                <div className='cta-loader'>
                  <p>Please wait. {loadingText}...</p>
                  <Loader />
                </div>
              ) : (
                <React.Fragment>
                  <div className='cta-title'>
                    <h1>
                      {ctaCategories &&
                      (ctaCategories.some((c) => c.category_name === 'business') ||
                        ctaCategories.some((c) => c.category_name === 'identity'))
                        ? `Confirm your information`
                        : ctaInfo && ctaInfo.title}
                    </h1>
                    {canBeDismissed && (
                      <button className='cta-dismiss-button' onClick={handleClose}>
                        <span className='material-icons'>close</span>
                      </button>
                    )}
                  </div>
                  {submitError && (
                    <div className='cta-error'>Server error: {submitError.error_message}</div>
                  )}
                  <div className='cta-form-wrapper' id='cta-form-wraper'>
                    {ctaInfo && ctaInfo.message && (
                      <div className='cta-message'>
                        <p>{ctaInfo.message}</p>
                      </div>
                    )}
                    {(ctaCategories &&
                      ctaCategories.some((c) => c.category_name === 'business')) && (
                      <div className='cta-business-information cta-form-group'>
                        <p className='cta-header'>Business Information</p>
                        <p className='cta-info'>
                          The below information must EXACTLY match what is on file with the IRS (SS4 form).
                        </p>
                        <div className='form-field-two-col'>
                          <TextInput
                            value={businessLegalName}
                            onChange={(val) => this.handleChange(val, 'businessLegalName')}
                            label='Legal Name'
                            onBlur={(e) => this.validate('businessLegalName', e.target.value)}
                            error={!!businessLegalNameError}
                            helperText={businessLegalNameError && businessLegalNameError.message}
                          />
                          <TextInput
                            value={businessEin}
                            onChange={(val) => this.handleChange(val, 'businessEin')}
                            label='Tax ID/EIN'
                            onBlur={(e) => this.validate('businessEin', e.target.value)}
                            error={!!businessEinError}
                            helperText={businessEinError && businessEinError.message}
                          />
                        </div>
                        <div className='form-field-two-col'>
                          <TextInput
                            value={businessAddress1}
                            onChange={(val) => this.handleChange(val, 'businessAddress1')}
                            label='Legal Address'
                            onBlur={(e) => this.validate('businessAddress1', e.target.value)}
                            error={!!businessAddress1Error}
                            helperText={businessAddress1Error && businessAddress1Error.message}
                          />
                          <TextInput
                            value={businessAddress2}
                            onChange={(val) => this.handleChange(val, 'businessAddress2')}
                            label='Legal Address (Line 2)'
                            onBlur={(e) => this.validate('businessAddress2', e.target.value)}
                            error={!!businessAddress2Error}
                            helperText={businessAddress2Error && businessAddress2Error.message}
                          />
                          {businessAddress1 && (
                            <div className='cta-address-tip-container'>
                              <div className='flex'>
                                <p className='cta-address-tip-text'>
                                  P.O. Boxes or Paid Addresses are not allowed.
                                </p>
                                <Tooltip title={addressTooltipMessage} arrow='true'>
                                  <p className='cta-address-tooltip-text'>
                                    <span>Why</span>
                                    <span className='material-icons'>help_outline</span>
                                  </p>
                                </Tooltip>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='form-field-two-col city-state-zip'>
                          <TextInput
                            value={businessCity}
                            onChange={(val) => this.handleChange(val, 'businessCity')}
                            label='City'
                            onBlur={(e) => this.validate('businessCity', e.target.value)}
                            error={!!businessCityError}
                            helperText={businessCityError && businessCityError.message}
                          />
                          <div className={contactCountry.value !== 'GBR' ? 'form-field-two-col' : ''}>
                            {businessCountry.value !== 'GBR' && (
                              <React.Fragment>
                                <Selector
                                  name='businessState'
                                  component={FormField}
                                  label='State/Province'
                                  type='select'
                                  options={this._getStateOptions(this.state.businessCountry)}
                                  value={businessState}
                                  onChange={(newValue) => {
                                    this._onChangeBusiness(newValue);
                                  }}
                                />
                                {businessStateError && (
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      fontWeight: '400',
                                      marginTop: '10px',
                                      marginLeft: '10px',
                                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                      color: '#f44336',
                                      display: 'block',
                                      width: '350px',
                                    }}
                                  >
                                    {businessStateError && businessStateError.message}
                                  </span>
                                )}
                              </React.Fragment>
                            )}
                            <TextInput
                              value={businessZip}
                              onChange={(val) => this.handleChange(val, 'businessZip')}
                              label='Zip'
                              onBlur={(e) => this.validate('businessZip', e.target.value)}
                              error={!!businessZipError}
                              helperText={businessZipError && businessZipError.message}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {(ctaCategories &&
                      ctaCategories.some((c) => c.category_name === 'identity')) && (
                      <div className='cta-identity-information cta-form-group'>
                        <p className='cta-header'>Identity Information</p>
                        <p className='cta-info'>
                          Please confirm that ALL the information below EXACTLY matches what is
                          listed on your government-issued documentation and update any information that is inaccurate.
                          Entering this information accurately is critical to ensuring there is no interruption to your
                          ability to accept payments and receive payouts.
                        </p>
                        <div className='form-field-two-col'>
                          <TextInput
                            value={firstName}
                            onChange={(val) => this.handleChange(val, 'firstName')}
                            label='First Name'
                            onBlur={(e) => this.validate('firstName', e.target.value)}
                            error={!!firstNameError}
                            helperText={firstNameError && firstNameError.message}
                            style={{ marginTop: '30px' }}
                          />
                          <TextInput
                            value={lastName}
                            onChange={(val) => this.handleChange(val, 'lastName')}
                            label='Last Name'
                            onBlur={(e) => this.validate('lastName', e.target.value)}
                            error={!!lastNameError}
                            helperText={lastNameError && lastNameError.message}
                            style={{ marginTop: '30px' }}
                          />
                        </div>
                        <div className='form-field-two-col'>
                          <TextInput
                            value={contactAddress1}
                            onChange={(val) => this.handleChange(val, 'contactAddress1')}
                            label='Address 1'
                            onBlur={(e) => this.validate('contactAddress1', e.target.value)}
                            error={!!contactAddress1Error}
                            helperText={contactAddress1Error && contactAddress1Error.message}
                          />
                          <TextInput
                            value={contactAddress2}
                            onChange={(val) => this.handleChange(val, 'contactAddress2')}
                            label='Address 2'
                            onBlur={(e) => this.validate('contactAddress2', e.target.value)}
                            error={!!contactAddress2Error}
                            helperText={contactAddress2Error && contactAddress2Error.message}
                          />
                        </div>
                        <div className='form-field-two-col city-state-zip'>
                          <TextInput
                            value={contactCity}
                            onChange={(val) => this.handleChange(val, 'contactCity')}
                            label='City'
                            onBlur={(e) => this.validate('contactCity', e.target.value)}
                            error={!!contactCityError}
                            helperText={contactCityError && contactCityError.message}
                          />
                          <div className={contactCountry.value !== 'GBR' ? 'form-field-two-col' : ''}>
                            {contactCountry.value !== 'GBR' && (
                              <React.Fragment>
                                <Selector
                                  name='contactState'
                                  component={FormField}
                                  label='State/Province'
                                  type='select'
                                  options={this._getStateOptions(this.state.contactCountry)}
                                  value={contactState}
                                  onChange={(newValue) => {
                                    this._onChangecontact(newValue);
                                  }}
                                  error={!!contactStateError}
                                  helperText={contactStateError && contactStateError.message}
                                />
                                {contactStateError && (
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      fontWeight: '400',
                                      marginTop: '10px',
                                      marginLeft: '10px',
                                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                      color: '#f44336',
                                      display: 'block',
                                      width: '350px',
                                    }}
                                  >
                                    {contactStateError && contactStateError.message}
                                  </span>
                                )}
                              </React.Fragment>
                            )}
                            <TextInput
                              value={contactZip}
                              onChange={(val) => this.handleChange(val, 'contactZip')}
                              label='Zip'
                              onBlur={(e) => this.validate('contactZip', e.target.value)}
                              error={!!contactZipError}
                              helperText={contactZipError && contactZipError.message}
                            />
                          </div>
                        </div>
                        <div className='form-field-two-col'>
                          <TextInput
                            value={contactDOB}
                            onChange={(val) => this.handleChange(val, 'contactDOB')}
                            label='Date of Birth'
                            onBlur={(e) => this.validate('contactDOB', e.target.value)}
                            error={!!contactDOBError}
                            helperText={contactDOBError && contactDOBError.message}
                          />
                          <div className='form-field-with-desc'>
                            <TextInput
                              value={contactSsn}
                              onChange={(val) => this.handleChange(val, 'contactSsn')}
                              label='Social Security Number'
                              onBlur={(e) => this.validate('contactSsn', e.target.value)}
                              error={!!contactSsnError}
                              helperText={contactSsnError && contactSsnError.message}
                            />
                            <span className='extra-info'>
                              <span className='material-icons'>lock</span>
                              <span>We use secure encryption to protect this data.</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {ctaDocumentsRequested.length > 0 && (
                      <div style={{ marginTop: '5px' }} className='cta-form-group'>
                        <p className='cta-header'>Documentation Request</p>
                        {ctaDocumentsRequested.map((fileType) => (
                          <AdditionalDocumentUploader
                            key={fileType}
                            uploadFile={this.props.uploadFile}
                            isRemoving={this.props.isRemoving}
                            fileType={fileType}
                            uploadedFilesStore={this.props.uploadedFilesStore}
                            isUploading={this.props.isUploading}
                            removeFile={this.props.removeFile}
                            accept={this.additionalDocumentsObj[fileType].acceptedFiles}
                            isUpdating={this.props.isUpdating}
                            ctaSuccesslySubmitted = {this.props.ctaSuccesslySubmitted}
                          >
                            {this.additionalDocumentsObj[fileType].html}
                          </AdditionalDocumentUploader>
                        ))}
                      </div>
                    )}
                    {((ctaCategories &&
                      (ctaCategories.some((c) => c.category_name === 'business') ||
                        ctaCategories.some((c) => c.category_name === 'identity'))) ||
                      (ctaDocumentsRequested && ctaDocumentsRequested.length > 0)) && (
                      <div className='cta-miscellaneous-info'>
                        <Tooltip className='cta-tooltip-wrapper' title={tooltipMessage} arrow='true'>
                          <p className='cta-tooltip'>
                            <span>
                              Why is this required?
                              <span className='material-icons'>help_outline</span>
                            </span>
                          </p>
                        </Tooltip>
                        {(ctaCategories &&
                          (ctaCategories.some((c) => c.category_name === 'business') ||
                            ctaCategories.some((c) => c.category_name === 'identity'))) && (
                          <div className='cta-verification'>
                            <Field
                              name='canBeDismissed'
                              component={FormField}
                              type='material-checkbox'
                              showLabel={false}
                              label='I acknowledge that all the information provided above is true,
                               accurate and matches a government-issued form of identification and understand
                               additional documentation may be required if the information cannot be successfully
                               verified (ID card, Driver’s License, Passport, and/or Social Security Card).'
                              isChecked={verified === 'true'}
                              onChange={(event, value) => {
                                this.handleChange(value.toString(), 'verified')
                              }}
                            />
                          </div>
                        )}
                        {ctaDocumentsRequested.length > 0 && (
                        <div className='cta-verification'>
                        <Field
                          name='canBeDismissed'
                          component={FormField}
                          type='material-checkbox'
                          showLabel={false}
                          label='I acknowledge that all the documents provided above are true,
                          accurate and meet the criteria listed and understand additional documentation
                          may be required if the criteria are not met or
                          if the submitted document cannot be verified'
                          isChecked={verifiedDocs === 'true'}
                          onChange={(event, value) => { this.handleChange(value.toString(), 'verifiedDocs') }}
                        />
                          </div>
                        )}
                      </div>
                    )}
                  {termsOfService && (
                    <div className='terms-and-conditions'>
                      <p className='terms-header'>Terms and Conditions</p>
                      <p>
                      I understand and have read the terms of services and the business indicated above agrees to be
                      bound by the terms contained therein. The entity described on the foregoing Merchant Services
                      Application (the 'Merchant') hereby also agrees to the Stack Sports Terms of Use and
                      StackPay Inc. Terms of Use.
                      </p>
                      <p>
                      By selecting each box, I confirm that I have read, understand and accept
                      the information contained in the webpages linked below.
                      </p>
                      <div className='check-container-wrapper'>
                        {this.props.formInfo && this.props.formInfo.processorName === 'Stripe' ? (
                          <div className='tosCheckContainer'>
                            <Field
                              name='stackPayTOS'
                              component={FormField}
                              type='material-checkbox'
                              showLabel={false}
                              isChecked={stackPayTOS}
                              onChange={this._handleStackPayTOSChange}
                              label={
                                <div className='terms-check-label'>
                                  StackPay Inc. Terms of Use&nbsp;&nbsp;
                                  <a
                                    href='https://stackpay.com/terms-of-service-II/'
                                    className='terms-button'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    <span className='label'>View</span>
                                    <span className='material-icons'>navigate_next</span>
                                  </a>
                                </div>
                              }
                            />
                          </div>
                        ) : (
                          <div className='tosCheckContainer'>
                            <Field
                              name='stackPayTOS'
                              component={FormField}
                              type='material-checkbox'
                              showLabel={false}
                              isChecked={stackPayTOS}
                              onChange={this._handleStackPayTOSChange}
                              label={
                                <div className='terms-check-label'>
                                  StackPay Inc. Terms of Use&nbsp;&nbsp;
                                  <a
                                    href='https://stackpay.com/terms-of-service/'
                                    className='terms-button'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    <span className='label'>View</span>
                                    <span className='material-icons'>navigate_next</span>
                                  </a>
                                </div>
                              }
                            />
                          </div>
                        )}
                        <div className='tosCheckContainer'>
                          <Field
                            name='stackSportsTOS'
                            component={FormField}
                            type='material-checkbox'
                            showLabel={false}
                            isChecked={stackSportsTOS}
                            onChange={this._handleStackTOSChange}
                            label={
                              <div className='terms-check-label'>
                                Stack Sports Terms of Use&nbsp;&nbsp;
                                <a
                                  href='https://stacksports.com/legal-terms/'
                                  className='terms-button'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <span className='label'>View</span>
                                  <span className='material-icons'>navigate_next</span>
                                </a>
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='buttons'>
                    {ctaCategories &&
                    (ctaCategories.some((c) => c.category_name === 'business') ||
                      ctaCategories.some((c) => c.category_name === 'identity') ||
                      ctaDocumentsRequested.length > 0) ? (
                      <Button
                      disabled={verified !== 'true'
                        || verifiedDocs !== 'true'
                        || hasAllRequestedDocuments !== true
                        || (termsOfService && (stackPayTOS !== true || stackSportsTOS !== true))}
                        loading={isUpdating}
                        onClick={this.submit}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button onClick={this.submitMessage}>Okay</Button>
                    )}
                  </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
        {this.props.showConfirmationModal && (
          <div className='cta-wrapper'>
            <div className='cta-container confirmation-container'>
              <span className='material-icons'
                style={{ fontSize: '56px', color: '#4CAF50', marginLeft: '5px' }}
              >
                verified
              </span>
              <div className='cta-title'>
                <h1 style={{ boxShadow: 'none' }}>Your documents have been successfully submitted.</h1>
              </div>
              <div className='confirmation-body'>
                <p>
                  Your documents are now being reviewed,{' '}
                  please allow up to 3 business days for the review to be completed.
                </p>
                <p>
                  You will recieve an email once the review has been completed.
                </p>
                <p>
                  Please not that if we are unable to successfully{' '}
                  verify your documents additional documentation may be required.
                </p>
              </div>
              <div className='buttons'>
                <Button onClick={this.props.closeConfirmationModal}>
                  close
                </Button>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}
const MerchantInfoCTAForm = reduxForm({
  form: 'MerchantInfoCTA',
  validate: validator,
})(MerchantInfoCTA)

export default MerchantInfoCTAForm

