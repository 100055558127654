import Immutable from 'seamless-immutable'
import { MerchantActionTypes, } from './actions'
import { isSubmitting } from 'redux-form'

const initialState = Immutable({
  isFetching: true,
  isSubmitting: false,
  isFetchingRatesList: false,
  showSuccessModal: false,
  accountUpdated: false,
  merchantProfile: {},
  beneficialData: {},
  fullRatesList: {},
  merchantAlerts: {},
  isFetchingMerchantAlerts: true,
})

const ACTION_HANDLERS = {
  [MerchantActionTypes.FETCH_MERCHANT_PROFILE]: (state) => state.merge({
    isFetching: true,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_PROFILE_SUCCESS]: (state, { data, }) => state.merge({
    isFetching: false,
    merchantProfile: data.data,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_PROFILE_ERROR]: (state, { error, }) => state.merge({
    isFetching: false,
    error,
  }),


  [MerchantActionTypes.FETCH_FULL_RATES_LIST]: (state) => state.merge({
    isFetchingRatesList: true,
    fullRatesList: null,
  }),

  [MerchantActionTypes.FETCH_FULL_RATES_LIST_SUCCESS]: (state, { data, }) => state.merge({
    isFetchingRatesList: false,
    fullRatesList: data.data,
  }),


  [MerchantActionTypes.FETCH_FULL_RATES_LIST_ERROR]: (state, { error, }) => state.merge({
    isFetchingRatesList: false,
    error,
  }),

  [MerchantActionTypes.UPDATE_MERCHANT_STATUS]: (state, { merchantId, merchantStatus, closureReason, }) => state.merge({
    isSubmitting: true,
    accountUpdated: false,
  }),

  [MerchantActionTypes.UPDATE_MERCHANT_STATUS_SUCCESS]: (state) => state.merge({
    isSubmitting: false,
    accountUpdated: true,
  }),

  [MerchantActionTypes.UPDATE_MERCHANT_STATUS_SUCCESS_NO_RELOAD]: (state) => state.merge({
    isSubmitting: false,
    accountUpdated: false,
  }),

  [MerchantActionTypes.UPDATE_MERCHANT_STATUS_ERROR]: (state, { error, notification, }) => state.merge({
    isSubmitting: false,
    accountUpdated: false,
    error,
    notification,
  }),
  
  [MerchantActionTypes.SEND_BENEFICIAL_DATA]: (state, { merchantId, data }) => state.merge({
    isSubmitting: true,
  }),

  [MerchantActionTypes.SEND_BENEFICIAL_DATA_SUCCESS]: (state, { data, }) => state.merge({
    isSubmitting: false,
    beneficialData: data,
  }),

  [MerchantActionTypes.SEND_BENEFICIAL_DATA_ERROR]: (state, { error, }) => state.merge({
    isSubmitting: false,
    error,
  }),

  //
  // [MerchantActionTypes.FETCH_MERCHANT_GATEWAY_STATUS]: (state) => state.merge({
  //   isFetchingMerchantGatewayStatus: true,
  // }),
  //
  // [MerchantActionTypes.FETCH_MERCHANT_GATEWAY_STATUS_SUCCESS]: (state, { data, }) => state.merge({
  //   isFetchingMerchantGatewayStatus: false,
  //   merchantGatewayStatus: data.status,
  // }),
  //
  // [MerchantActionTypes.FETCH_MERCHANT_GATEWAY_STATUS_ERROR]: (state, { error, }) => state.merge({
  //   isFetchingMerchantGatewayStatus: false,
  //   error,
  // }),

  [MerchantActionTypes.REVERT_PROCESSOR]: (state) => state.merge({
    isRevertingProcessor: true,
    accountUpdated: false,
  }),

  [MerchantActionTypes.REVERT_PROCESSOR_SUCCESS]: (state, { data, }) => state.merge({
    isRevertingProcessor: false,
    accountUpdated: true,
  }),

  [MerchantActionTypes.REVERT_PROCESSOR_ERROR]: (state, { error, }) => state.merge({
    isRevertingProcessor: false,
    error,
  }),

  [MerchantActionTypes.ACCEPT_T_O_S]: (state) => state.merge({
    isSubmitting: true,
    accountUpdated: false,
  }),

  [MerchantActionTypes.ACCEPT_T_O_S_SUCCESS]: (state) => state.merge({
    isSubmitting: false,
    accountUpdated: true,
  }),

  [MerchantActionTypes.ACCEPT_T_O_S_ERROR]: (state, { error, }) => state.merge({
    isSubmitting: false,
    error,
  }),

  [MerchantActionTypes.UPDATE_FUNDING_HOLD]: (state, { merchantId, data, }) => state.merge({
    isSubmitting: true,
    accountUpdated: false,
  }),

  [MerchantActionTypes.UPDATE_FUNDING_HOLD_SUCCESS]: (state) => state.merge({
    isSubmitting: false,
    accountUpdated: true,
    showSuccessModal: 'Merchant updated successfully',
  }),

  [MerchantActionTypes.UPDATE_FUNDING_HOLD_ERROR]: (state, { error, notification, }) => state.merge({
    isSubmitting: false,
    accountUpdated: false,
    error,
    notification,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_ALERTS]: (state) => state.merge({
    isFetchingMerchantAlerts: true,
    merchantAlerts: null,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_ALERTS_SUCCESS]: (state, { data, }) => state.merge({
    merchantAlerts: data,
  }),

  [MerchantActionTypes.FETCH_MERCHANT_ALERTS_ERROR]: (state, { error, }) => state.merge({
    isFetchingMerchantAlerts: false,
    error,
  }),
}

export default function applicationFormReducer(state = initialState, action) {
  try {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
  } catch (e) {
    console.error(e)
    return e
  }
}
