/* eslint-disable import/prefer-default-export */
import LoginActionCreators from 'ducks/Auth/actions'

// Layouts
import CoreLayout from 'layouts/CoreLayout'
import CheckoutLayout from 'layouts/CheckoutLayout'
import LoginLayout from 'layouts/LoginLayout'
import SignupLayout from 'layouts/SignupLayout'
import BlankLayout from 'layouts/BlankLayout'
import MerchantApplicationLayout from 'layouts/MerchantApplicationLayout'
import AdminMerchantLayout from '../layouts/MerchantLayout'
import AdminLayout from './AdminPortal/layouts/MainLayout'
import AdminBulkToolsLayout from './AdminPortal/layouts/BulkToolsLayout'

// Merchant Portal Pages
import SignInScreen from './SignIn'
import ConfirmationLandingScreen from './ConfirmationLanding'
import SignupScreen from './Signup'
import ApplicationFormScreen from './ApplicationForm'
import DashboardScreen from './Dashboard'
import Checkout from './Checkout'
import TransactionReportScreen from './TransactionReport'
import TransactionDetailScreen from './TransactionDetail'
import ReconciliationReportScreen from './ReconciliationReport'
import DepositDetail from './DepositDetail'
import ChargebackReportScreen from './ChargebackReport'
import ACHReturnReportScreen from './ACHReturnReport'
import AccountInfoScreen from './AccountInfo'
import AboutUs from './AboutUs'
import PendingApplications from './PendingApplications'
import UsersInfo from './UsersInfo'
import DeviceManagement from './DeviceManagement'
import PaymentPlans from './PaymentPlans'
import Subscriptions from './Subscriptions'
import SubscriptionReportScreen from './SubscriptionReport'
import CustomerReportScreen from './CustomerReport'
import CustomerDetailScreen from './CustomerDetail'
import SubscriptionDetails from './SubscriptionDetail'
import DisputeDetailScreen from './DisputeDetail'

// Admin Portal Pages
import Dashboard from './AdminPortal/pages/Dashboard'
import MerchantList from './AdminPortal/pages/MerchantList'
import PartnerList from './AdminPortal/pages/PartnerList'
import RatesList from './AdminPortal/pages/RatesList'
import PartnerProfile from './AdminPortal/pages/PartnerProfile'
import RatesProfile from './AdminPortal/pages/RatesProfile'
import PartnerCreation from './AdminPortal/pages/PartnerCreation'
import UserAdministrator from './AdminPortal/pages/UserAdministrator'
import TransactionDetailsReport from './AdminPortal/pages/TransactionDetailsReport'
import TransactionDetail from './AdminPortal/pages/TransactionDetail'
import BatchReconciliationReport from './AdminPortal/pages/BatchReconciliationReport'
import AdminDepositDetail from './AdminPortal/pages/DepositDetail'
import ProcessorReconciliationReport from './AdminPortal/pages/ProcessorReconciliationReport'
import ChargebackRetrievalsReport from './AdminPortal/pages/ChargebackRetrievalsReport'
import OutageTransactionsReport from './AdminPortal/pages/OutageTransactionsReport'
import InterchangeReport from './AdminPortal/pages/InterchangeReport'
import ACHReturnReport from './AdminPortal/pages/ACHReturnReport'
import FundingHoldReport from './AdminPortal/pages/FundingHoldReport'
import Invalid1099KReport from './AdminPortal/pages/Invalid1099KReport'
import EmailHistoryReport from './AdminPortal/pages/EmailHistoryReport'
import PendingAccountsReport from './AdminPortal/pages/PendingAccountsReport'
import LimitsReport from './AdminPortal/pages/LimitsReport'
import MigrationTool from './AdminPortal/pages/MigrationTool'
import PlatformPayout from './AdminPortal/pages/PlatformPayout'
import ResidualFeesReport from './AdminPortal/pages/ResidualFeesReport'
import SubscriptionsReport from './AdminPortal/pages/SubscriptionsReport'
import PartnerVolumeReport from './AdminPortal/pages/PartnerVolumeReport'
import CallToActionList from './AdminPortal/pages/CallToActionList'
import CallToActionCreate from './AdminPortal/pages/CallToActionCreate'
import CallToActionEdit from './AdminPortal/pages/CallToActionEdit'
import DisputeDetail from './AdminPortal/pages/DisputeDetail'
import AdminSubscriptionDetail from './AdminPortal/pages/SubscriptionDetail'

// Admin Merchant Pages
import MerchantProfile from './AdminPortal/pages/Merchant/pages/MerchantProfile'
import MerchantContact from './AdminPortal/pages/Merchant/pages/MerchantContact'
import MerchantLimits from './AdminPortal/pages/Merchant/pages/MerchantLimits'
import MerchantBanking from './AdminPortal/pages/Merchant/pages/MerchantBanking'
import MerchantUsers from './AdminPortal/pages/Merchant/pages/MerchantUsers'
import MerchantNotifications from './AdminPortal/pages/Merchant/pages/MerchantNotifications'
import MerchantDocuments from './AdminPortal/pages/Merchant/pages/MerchantDocuments'
import MerchantHistory from './AdminPortal/pages/Merchant/pages/MerchantHistory'
import MerchantMisc from './AdminPortal/pages/Merchant/pages/MerchantMisc'
import MerchantVolume from './AdminPortal/pages/Merchant/pages/MerchantVolume'

// Admin Bulk Tools
import BulkAddUsers from './AdminPortal/pages/BulkTools/pages/AddUsers'
import BulkUpdateRates from './AdminPortal/pages/BulkTools/pages/UpdateRates'
import UpdateAccountStatus from './AdminPortal/pages/BulkTools/pages/UpdateAccountStatus'

export const createRoutes = (store) => ([
  {
    path: '/subscriptions/:id',
    component: BlankLayout,
    indexRoute: Subscriptions(store),
  },
  {
    path: '/verify-dispute-redirect/:key/:disputeId/:type',
    component: CoreLayout,
    indexRoute: DisputeDetailScreen(store),
  },
  {
    path: '/dispute-details/:disputeId/:type',
    component: CoreLayout,
    indexRoute: DisputeDetailScreen(store),
  },
  {
    path: '/dispute-details-redirect/:disputeId/:type',
    onEnter: (nextState, transition) => {
      const auth = store.getState().auth
      const { pathname } = nextState.location

      const pathSegments = pathname.split('/')
      const disputeId = pathSegments[pathSegments.length - 2]
      const disputeType = pathSegments[pathSegments.length - 1]
    
      if (auth.isAuthenticated && pathname.includes('dispute-details-redirect')) {
        window.localStorage.setItem('lastURL', window.location.href)

        transition({
          pathname: `/dispute-detail/${disputeId}/${disputeType}`,
        })
      } else {
        const shouldRedirect = false
        store.dispatch(LoginActionCreators.passiveLogout(shouldRedirect))
        window.location.replace(
          `${process.env.SP_TURNSTILE_LOGIN_URL}&dispute=${disputeId}&type=${disputeType}`)
      }
    },
  },
  {
    path: '/payment-plans',
    component: BlankLayout,
    indexRoute: PaymentPlans(store),
  },
  {
    path: '/signup',
    onEnter: (nextState, transition) => {
      const auth = store.getState().auth
      const { pathname, search, } = nextState.location

      if (auth.isAuthenticated && pathname.includes('signup') && search) {
        window.localStorage.setItem('lastURL', window.location.href)

        transition({
          pathname: '/apply',
          search,
        })
      } else {
        const shouldRedirect = false
        store.dispatch(LoginActionCreators.passiveLogout(shouldRedirect))
        window.location.replace(
          `${process.env.SP_TURNSTILE_LOGIN_URL}&application=${search.substring(1, search.length)}`)
      }
    },
  },
  {
    path: '/start',
    component: SignupLayout,
    indexRoute: SignupScreen(store),
    onEnter: (nextState, transition) => {
      window.localStorage.setItem('lastURL', window.location.href)
    },
  },
  {
    path: '/',
    component: CoreLayout,
    onEnter: (nextState, transition) => {
      const auth = store.getState().auth
      const { pathname, search, } = nextState.location
      const { data, errors } = store.getState().account

      if (auth.isAuthenticated && errors && errors.status === '404') {
        transition({
          pathname: '/verify/error',
        })
      }

      if(!pathname.includes('login')){
        window.localStorage.setItem('lastURL', window.location.href);
      }

      // The user is not logged in, and is redirected to the Login page. However,
      // if the URL includes the word "signup", he/she has to be redirected to the
      // Sign Up page (using the route for the Sign Up page).
      if (!auth.isAuthenticated && !pathname.includes('signup') && !pathname.includes('payment-plans') && !pathname.includes('subscriptions')) {
        transition({
          pathname: '/login',
        })

        // The user is already logged in, and the URL includes the word "signup".
        // He/she is redirected to the Merchant Application form in order to create a merchant.
      } else if (auth.isAuthenticated && pathname.includes('signup') && search) {
        transition({
          pathname: '/apply',
          search,
        })

        // If the user was on the Additional Info form and tries to go somewhere else,
        // he/she is redirected back to that form because it has to be completed.
      } else if (!pathname.includes('apply') && data.profile && data.profile.additionalInfoStatus) {
        transition({
          pathname: '/apply',
        })
      }
    },
    indexRoute: DashboardScreen(store),
    childRoutes: [
      DashboardScreen(store),
      AccountInfoScreen(store),
      ConfirmationLandingScreen(),
      TransactionReportScreen(store),
      TransactionDetailScreen(store),
      DisputeDetailScreen(store),
      ChargebackReportScreen(store),
      ACHReturnReportScreen(store),
      ReconciliationReportScreen(store),
      SubscriptionReportScreen(store),
      CustomerReportScreen(store),
      CustomerDetailScreen(store),
      SubscriptionDetails(store),
      DepositDetail(store),
      // ApplicationFormScreen(store),
      AboutUs(),
      SignupScreen(store),
      PendingApplications(store),
      UsersInfo(store),
      DeviceManagement(store)
    ],
  },
  {
    path: '/checkout',
    onEnter: (nextState, transition) => {
      const auth = store.getState().auth

      if (!auth.isAuthenticated) {
        transition({ pathname: '/login', })
      }
    },
    component: CheckoutLayout,
    indexRoute: Checkout.Dashboard(store),
    childRoutes: [
      Checkout.Dashboard(store),
      Checkout.Settings(store),
      Checkout.PaymentLinks(store),
    ],
  },
  {
    path: '/apply',
    component: MerchantApplicationLayout,
    indexRoute: ApplicationFormScreen(store),
    onEnter: (nextState, transition) => {
      const auth = store.getState().auth
      const { pathname, search, } = nextState.location
      const { data, } = store.getState().account

      window.localStorage.setItem('lastURL', window.location.href);

      // The user is not logged in, and is redirected to the Login page. However,
      // if the URL includes the word "signup", he/she has to be redirected to the
      // Sign Up page (using the route for the Sign Up page).
      if (!auth.isAuthenticated && !pathname.includes('signup')) {
        transition({
          pathname: '/login',
        })

        // The user is already logged in, and the URL includes the word "signup".
        // He/she is redirected to the Merchant Application form in order to create a merchant.
      } else if (auth.isAuthenticated && pathname.includes('signup') && search) {
        transition({
          pathname: '/apply',
          search,
        })

        // If the user was on the Additional Info form and tries to go somewhere else,
        // he/she is redirected back to that form because it has to be completed.
      }
    },
  },
  {
    path: '/login',
    component: LoginLayout,
    onEnter: (nextState, transition) => {
      const auth = store.getState().auth
      if (auth.isAuthenticated) {
        transition({
          pathname: '/',
        })
      } else {
        const shouldRedirect = false
        store.dispatch(LoginActionCreators.passiveLogout(shouldRedirect))
        window.location.replace(process.env.SP_TURNSTILE_LOGIN_URL)
      }
    },
  },
  {
    path: '/verify/:key',
    component: LoginLayout,
    indexRoute: SignInScreen(store),
  },
  {
    path: '/verify-merchant-application/:key/:appToken',
    component: LoginLayout,
    indexRoute: SignupScreen(store),
  },
  {
    path: '/admin',
    onEnter: (nextState, transition) => {
      const auth = store.getState().auth

      if (!auth.isAuthenticated) {
        transition({ pathname: '/login', })
      }
    },
    component: AdminLayout,
    indexRoute: Dashboard(store),
    childRoutes: [
      Dashboard(store),
      MerchantList(store),
      // MerchantCreation(store),
      PartnerList(store),
      RatesList(store),
      PartnerProfile(store),
      RatesProfile(store),
      PartnerCreation(store),
      UserAdministrator(store),
      TransactionDetailsReport(store),
      TransactionDetail(store),
      BatchReconciliationReport(store),
      AdminDepositDetail(store),
      ProcessorReconciliationReport(store),
      ChargebackRetrievalsReport(store),
      DisputeDetail(store),
      OutageTransactionsReport(store),
      PartnerVolumeReport(store),
      InterchangeReport(store),
      ACHReturnReport(store),
      FundingHoldReport(store),
      Invalid1099KReport(store),
      EmailHistoryReport(store),
      LimitsReport(store),
      MigrationTool(store),
      PaymentPlans(store),
      PendingAccountsReport(store),
      PlatformPayout(store),
      CallToActionList(store),
      CallToActionCreate(store),
      CallToActionEdit(store),
      ResidualFeesReport(store),
      SubscriptionsReport(store),
      AdminSubscriptionDetail(store),
    ],
  },
  {
    path: '/admin/merchants/:id',
    onEnter: (nextState, transition) => {
      const auth = store.getState().auth

      if (!auth.isAuthenticated) {
        transition({ pathname: '/login', })
      }
    },
    component: AdminMerchantLayout,
    indexRoute: MerchantProfile(store),
    childRoutes: [
      MerchantProfile(store),
      MerchantLimits(store),
      MerchantContact(store),
      MerchantBanking(store),
      MerchantUsers(store),
      MerchantNotifications(store),
      MerchantDocuments(store),
      MerchantHistory(store),
      MerchantMisc(store),
      MerchantVolume(store),
    ],
  },
  {
    path: '/admin/bulk-tools',
    onEnter: (nextState, transition) => {
      const auth = store.getState().auth

      if (!auth.isAuthenticated) {
        transition({ pathname: '/login', })
      }
    },
    component: AdminBulkToolsLayout,
    indexRoute: BulkAddUsers(store),
    childRoutes: [
      BulkAddUsers(store),
      BulkUpdateRates(store),
      UpdateAccountStatus(store),
    ],
  },
])
